import type {
  ActionFunction,
  LinksFunction,
  LoaderFunction
} from '@remix-run/node';
import { Form, json, redirect, useActionData } from '@remix-run/react';
import { useEffect, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { SecondaryButton } from '~/components/atoms/button';
import { FlexColumn } from '~/components/atoms/container';
import { EducateIcon } from '~/components/icons/educate';
import { FormField } from '~/components/molecules/form-field';
import { getDomainUrl } from '~/misc';
import { getUserSession, sendToken } from '~/session.server';
import loginStyles from '~/styles/atoms/login.css?url';

export type ResponseItem = {
  data?: Record<string, any> | Record<string, any>[] | string[] | string;
  errors?: string[];
  metadata?: Record<string, any>;
};

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: loginStyles }
];

export const action: ActionFunction = async ({ request }) => {
  let formData = await request.formData();
  let response: ResponseItem = { errors: [] };
  let email = formData.get('email')?.toString();

  if (!email) {
    response.errors = response?.errors ?? [];
    response.errors.push('Please provide a valid email address');
  }

  if (response?.errors?.length) {
    return json(response, { status: 400 });
  }

  try {
    if (email) {
      let domainUrl = getDomainUrl(request);
      let magicLink = await sendToken(
        email.toString(),
        domainUrl,
        false //process.env.NODE_ENV === 'production'
      );

      if (magicLink) {
        return json({
          data: 'ok',
          metadata: {
            context: process.env.NODE_ENV
          }
        });
      }
    }

    return json(
      { errors: [`Oops looks like you don't have access to sign in yet.`] },
      { status: 200 }
    );
  } catch (err) {
    console.error(err);
    return json(
      {
        errors: [`Oops something we didn't anticipate happened.`],
        exception: err
      },
      { status: 500 }
    );
  }
};

export let loader: LoaderFunction = async ({ request }) => {
  let session = await getUserSession(request);

  if (session) {
    throw redirect(`/${session?.account_id}`);
  }

  return null;
};

export default function Login() {
  let formRef = useRef<HTMLFormElement>(null);
  let actionData = useActionData<ResponseItem>();

  useEffect(() => {
    if (actionData?.data === 'ok') {
      let message = 'Link sent. Check your inbox/spam folders.';

      if (actionData?.metadata?.context === 'development') {
        message = 'Link sent. Check your console.';
      }

      toast.success(message);
      formRef.current?.reset();
    }

    if (actionData?.errors?.length) {
      actionData?.errors?.forEach(error => {
        toast.error(error);
      });
    }
  }, [actionData]);

  return (
    <FlexColumn alignment="center" style={{ flex: 1, marginTop: 100 }}>
      <EducateIcon />

      <Form
        method="post"
        replace
        ref={formRef}
        style={{ maxWidth: 500, width: '100%', marginTop: 64 }}
        className="login__form"
      >
        <FormField
          name="email"
          type="email"
          className="login__input"
          placeholder="jeandoe@mail.com"
        />
        <SecondaryButton
          type="submit"
          style={{ marginTop: 16, width: '100%' }}
          className="login__btn"
        >
          Send me a login link
        </SecondaryButton>
        <Toaster />
      </Form>
    </FlexColumn>
  );
}
